import React from "react"
import styled from "styled-components"
// import { GoPrimitiveDot } from "react-icons/go"
import { Col } from "react-bootstrap"
import { breakpoints } from "utils"
import { IArticle } from "api/types"
import Img from "gatsby-image"
import { navigate } from "gatsby"
import { PinkAppButton } from "../../../components/buttons"
import { Button } from "react-bootstrap"

const Container = styled(Col)`
  cursor: pointer;
`
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: ${breakpoints.tablet}px) {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  padding: 10px 0;
`
const PinkAppButtons = styled(PinkAppButton)`
  width: 120px;
`
const Thumbnail = styled.img`
  width: 100px;
  height: 100px;
  margin-right: 16px;
  margin-bottom: 0;
  border-radius: 5px;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
`
const Title = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 10px;
`
const Subtitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: ${props => props.theme.colors.secondary};
`
// const Separator = styled(GoPrimitiveDot)`
//   margin: 0 5px;
// `

const PreviousItem: React.FC<IArticle> = props => (
  <Container md="6" xs="12" onClick={() => navigate(`/articles/${props.slug}`)}>
    <PinkAppButtons title="Previous"></PinkAppButtons>
    {/* <PrevNextButton>Previous</PrevNextButton> */}
  </Container>
)

export default PreviousItem
