import { IArticleMeta } from "models/ArticleMeta"
import React from "react"
import { Row } from "react-bootstrap"
import styled from "styled-components"
import { breakpoints } from "utils"
import { Heading2 } from "../ui"
import PostItem from "./PostItem"
import { IRelatedArticle } from "api/types"

const Container = styled.div`
  margin-top: 20px;
`
const Wrapper = styled(Row)`
  @media (max-width: ${breakpoints.tablet}px) {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`

const renderPostItems = (posts: IRelatedArticle[]) =>
  posts.map((post, i) => {
    console.log("key " + i)
    return <PostItem key={i.toString()} {...post.node} />
  })

interface Props {
  items: IRelatedArticle[]
}

const RelatedPosts: React.FC<Props> = props => {
  return (
    <Container>
      <Heading2>Related articles</Heading2>
      <Wrapper>{renderPostItems(props.items)}</Wrapper>
    </Container>
  )
}

export default RelatedPosts
