// import { IVideoEmbed } from "models/IVideoEmbed"
import { IRelatedVideo } from "api/types"
import React from "react"
import styled from "styled-components"
import { Heading2 } from "../ui"
import VideoItem from "./VideoItem"

const Container = styled.div`
  margin-top: 20px;
`
const Wrapper = styled.div`
  display: flex;
  overflow-x: auto;
  align-items: flex-start;
`

const renderVideoItems = (posts: IRelatedVideo[]) =>
  posts.map((video, i) => <VideoItem key={i.toString()} {...video} />)

interface Props {
  items: IRelatedVideo[]
}

const RelatedVideos: React.FC<Props> = props => {
  return (
    <Container>
      <Heading2>Related videos</Heading2>
      <Wrapper>{renderVideoItems(props.items)}</Wrapper>
    </Container>
  )
}

export default RelatedVideos
