import styled from "styled-components"

const Tag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2px 7px;
  background: rgba(0, 0, 0, 0.07);
  border-radius: 3px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: ${props => props.theme.colors.secondary};
  margin-right: 8px;
`

export default Tag
