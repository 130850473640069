import React from "react"
import styled from "styled-components"
import { breakpoints } from "utils"
import { Heading2 } from "./ui"

const Container = styled.div`
  @media (max-width: ${breakpoints.tablet}px) {
    background: rgba(0, 0, 0, 0.05) !important;
    padding: 10px;
    overflow: none;
  }
`
const Title = styled(Heading2)`
  color: ${props => props.theme.colors.secondary};
  @media (max-width: ${breakpoints.tablet}px) {
    margin-top: 0;
  }
`
const Content = styled.ul``
const ItemContainer = styled.li`
  font-weight: normal;
  line-height: 24px;
  color: ${props => props.theme.colors.secondary};
  font-size: 16px;
`

const ListSection: React.FC = props => {
  return (
    <Container>
      <Content>{props.children}</Content>
    </Container>
  )
}

export const ListItem: React.FC = props => {
  return <ItemContainer>{props.children}</ItemContainer>
}

export default ListSection
