import _ from "lodash"
import React, { useEffect, useState } from "react"
import { Amplify } from "aws-amplify"
import { AWS_CONFIG } from "../../../aws-config"
import Breadcrumbs from "components/Breadcrumbs"
import styled, { css } from "styled-components"
import { breakpoints } from "utils"
import { H1, H7, LargeText } from "components/typography"
import ProfileCard from "components/ProfileCard"
import Tag from "components/Tag"
import { FaRegBookmark } from "react-icons/fa"
import { AiOutlinePlusCircle } from "react-icons/ai"
import { BiShareAlt } from "react-icons/bi"
import ActionLink from "./ActionLink"
import ResourceLink from "../../components/ResourceLink"
import RelatedPosts from "./RelatedPosts"
import RelatedVideos from "./RelatedVideos"
import { Col, Row } from "react-bootstrap"
import { IPageContext, IUsefulLink, UserType } from "api/types"
import { Heading2 } from "./ui"
import ContentWrapper from "./ContentWrapper"
import { apiPostBookmarkedArticles } from "api/queries"
import syncProfileWithSessionStorage from "components/QueryUserProfile/syncProfileWithSessionStorage"
import FeedbackSection from "./FeedbackSection"
import { navigate } from "gatsby"
import Previous from "./Previous"
import Next from "./Next"

const Container = styled(Row)`
  display: flex;
  margin-top: 27px;
  @media (max-width: ${breakpoints.desktop}px) {
    padding: 0 16px;
  }
`
const Sidebar = styled(Col)`
  margin-bottom: 24px;
`
const NextPrevious = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  border-top: 1px solid whitesmoke;
`
const Title = styled(H1)`
  margin: 16px 0;
  color: ${props => props.theme.colors.secondary};
`
const Excerpt = styled(LargeText)`
  margin-bottom: 16px;
`
const Author = styled(ProfileCard)`
  margin-bottom: 28px;
  @media (min-width: ${breakpoints.desktop}px) {
    margin-bottom: 96px;
  }
`
const Tags = styled.div`
  margin: 10px 0 36px;
  display: flex;
  cursor: pointer;
`
const Separator = styled.div`
  width: 100%;
  @media (min-width: ${breakpoints.tablet}px) {
    max-width: 280px;
  }
  height: 0px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin: 36px 0;
`
const Content = styled(Col)``
const ResourceLinks = styled.div`
  & > div {
    margin-top: 16px;
  }
  max-width: 280px;
  @media (max-width: ${breakpoints.tablet}px) {
    display: none;
  }
`
const MobileResourceLinksWrapper = styled.div`
  @media (min-width: ${breakpoints.tablet}px) {
    display: none;
  }
`
const iconStyles = css`
  height: 28px;
  width: 28px;
  stroke: #979797;
`
const BookmarkIcon = styled(FaRegBookmark)`
  ${iconStyles};
`
const ShareIcon = styled(BiShareAlt)`
  ${iconStyles};
`

const renderResourceLinks = (resources: IUsefulLink[]) =>
  resources.map((resource: IUsefulLink, i) => (
    <ResourceLink
      link={resource.link}
      text={resource.title}
      key={i.toString()}
    />
  ))

interface PostProps {
  pageContext: IPageContext
  loggedInUser?: UserType | null
}

const Post: React.FC<PostProps> = props => {
  const { pageContext, loggedInUser } = props
  const {
    data,
    content,
    related_articles,
    previous_articles,
    next_articles,
    articleType,
    topic_title,
    topic_id,
  } = pageContext

  const [bookmarks, setBooksMarks] = useState<string[] | null>()

  const [isBookmarked, setIsBookmarked] = useState<boolean>(false)
  let breadcrumbs: any[] = []
  switch (articleType) {
    case "manage":
      breadcrumbs = [{ text: "Manage my early menopause", link: "/manage" }]
      break
    case "discover":
      breadcrumbs = [{ text: "Discover", link: "/discover" }]
      break
    default:
      break
  }

  const init = () => {
    if (loggedInUser && loggedInUser?.bookmarkedArticles) {
      const bookmarkedArticles = loggedInUser?.bookmarkedArticles
        ? (loggedInUser?.bookmarkedArticles.filter(Boolean) as string[])
        : []
      setIsBookmarked(_.includes(bookmarkedArticles, data.slug))
      setBooksMarks(bookmarkedArticles)
    }
  }
  const renderTags = (tag: any) => (
    // @ts-ignore
    <Tag onClick={() => navigate(`/${articleType}?topic=${tag.id}`)}>
      {tag.title}
    </Tag>
  )

  const onBookmark = async () => {
    try {
      let params = []
      if (!isBookmarked) {
        params = [...(bookmarks || []), data.slug]
        const result = await apiPostBookmarkedArticles(params)
        setBooksMarks(params)
        setIsBookmarked(true)
        syncProfileWithSessionStorage({ ...loggedInUser, ...result })
      } else {
        params = bookmarks ? bookmarks.filter(item => item !== data.slug) : []
        const result = await apiPostBookmarkedArticles(params)
        setBooksMarks(params)
        setIsBookmarked(false)
        syncProfileWithSessionStorage({ ...loggedInUser, ...result })
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    Amplify.configure(AWS_CONFIG)
    init()
  }, [loggedInUser])

  return (
    <Container>
      <Sidebar sm="12" md="4">
        <Breadcrumbs
          items={breadcrumbs}
          activeItem={{ text: data.title, link: `/articles/${data.slug}` }}
        />
        <Title>{data.title}</Title>
        {/* <Excerpt>{data.description}</Excerpt> */}
        <H7>CATEGORY</H7>
        <Tags>{renderTags({ id: topic_id, title: topic_title })}</Tags>
        <Separator />
        {loggedInUser && (
          <ActionLink
            className={isBookmarked ? "active" : ""}
            text={isBookmarked ? "Unmark" : "Bookmark"}
            icon={BookmarkIcon}
            onClick={onBookmark}
          />
        )}
        <ActionLink
          text="Share"
          icon={ShareIcon}
          onClick={() => console.log("clicked share action link")}
        />
        <ResourceLinks>
          <Separator />
          <H7>RESOURCES</H7>
          <div>
            {data.useful_links &&
              data.useful_links.length &&
              renderResourceLinks(data.useful_links)}
          </div>
        </ResourceLinks>
      </Sidebar>
      <Content sm="12" md="8">
        <ContentWrapper
          content={content}
          videos={data.videos}
          headerCover={data.header_cover}
        />
        {data.useful_links && data.useful_links.length && (
          <MobileResourceLinksWrapper>
            <Heading2>Resources</Heading2>
            {renderResourceLinks(data.useful_links)}
          </MobileResourceLinksWrapper>
        )}
        {related_articles && related_articles.length ? (
          <RelatedPosts items={related_articles} />
        ) : null}
        {data.related_videos && data.related_videos.length ? (
          <RelatedVideos items={data.related_videos} />
        ) : null}
        <FeedbackSection
          reference={data.slug}
          userProfileExists={Boolean(loggedInUser)}
        />
        <NextPrevious>
          {previous_articles && previous_articles.length ? (
            <Previous items={previous_articles} />
          ) : null}
          {next_articles && next_articles.length ? (
            <Next items={next_articles} />
          ) : null}
        </NextPrevious>
      </Content>
    </Container>
  )
}

export default Post
