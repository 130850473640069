import React from "react"
import { IArticleContent, IVideoEmbed } from "api/types"
import VideoEmbed from "./VideoEmbed"
import { Paragraph, Heading2 } from "./ui"
import ListSection, { ListItem } from "./ListSection"
import Interweave from "interweave"
import Img from "gatsby-image"
import styled, { css } from "styled-components"
import { breakpoints } from "utils"

interface Props {
  content: IArticleContent[]
  videos?: IVideoEmbed[]
  headerCover?: any
}

const ImgContainer = styled.div`
  max-width: 900px;
  /* max-height: 200px; */
  object-fit: contain;
  @media (max-width: ${breakpoints.tablet}px) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
`

const applyImgStyle = (position: string) => {
  switch (position) {
    case "left":
      return {
        float: "left",
        margin: "10px 24px 10px 24px",
      }
    case "right":
      return {
        margin: "10px 10px 10px 10px",
        float: "right",
      }
    case "top":
      return {
        width: "100%",
        marginBottom: "40px",
      }
    default:
      return {}
  }
}
const ContentWrapper: React.FC<Props> = ({ content, videos, headerCover }) => {
  return (
    <>
      {headerCover ? (
        <Img
          fluid={headerCover.childImageSharp.fluid}
          style={{
            width: "100%",
            height: "300px",
            marginBottom: 0,
            borderRadius: "10px",
          }}
        />
      ) : null}
      {videos && videos.length
        ? videos.map((video, i) => (
            <VideoEmbed
              key={i}
              imageUrl={video?.imageUrl}
              embedUrl={video?.embedUrl as string}
              cover
            />
          ))
        : null}
      {content.map((item, i) => (
        <div key={i}>
          {item.heading?.default && (
            <Heading2>
              <Interweave content={item.heading?.default} />
            </Heading2>
          )}
          {item.paragraph?.image && (
            <ImgContainer>
              <Img
                fluid={item.paragraph?.image.src.childImageSharp.fluid}
                style={{
                  width: item.paragraph?.image.width,
                  height: item.paragraph?.image.height,
                  marginTop: "10px",
                  ...applyImgStyle(item.paragraph?.image.position),
                  borderRadius: "15px",
                  objectRift: "contain",
                }}
              />
            </ImgContainer>
          )}
          {item.paragraph?.default && (
            <Paragraph>
              <Interweave content={item.paragraph?.default} />
            </Paragraph>
          )}
          {item.bullets ? (
            <ListSection>
              {item.bullets.default.map((bullet, i) => (
                <ListItem key={i}>
                  <Interweave content={bullet}></Interweave>
                </ListItem>
              ))}
            </ListSection>
          ) : null}
        </div>
      ))}
    </>
  )
}

export default ContentWrapper
