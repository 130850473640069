import PlayIcon from "components/PlayIcon"
import React, { useState } from "react"
import styled, { css } from "styled-components"
import { breakpoints } from "utils"
import Img from "gatsby-image"
import ModalVideo from "react-modal-video"

const Container: any = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  ${(props: any) => {
    if (props.wrapLeft) {
      return css`
        float: left;
        width: 50%;
        margin-right: 24px;
      `
    } else if (props.wrapRight) {
      return css`
        float: right;
        width: 50%;
        margin-left: 24px;
      `
    } else if (props.cover) {
      return css`
        margin-bottom: 38px;
      `
    }
  }}
  @media (max-width: ${breakpoints.tablet}px) {
  }
`
const Image = styled.img`
  width: 100%;
  margin-bottom: 0;
  border-radius: 10px;
  @media (max-width: ${breakpoints.tablet}px) {
    border-radius: 0;
  }
`
const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

interface Props {
  imageUrl: any
  embedUrl: string
  cover?: boolean
  wrapLeft?: boolean
  wrapRight?: boolean
}

const VideoEmbed: React.FC<Props> = ({
  imageUrl,
  embedUrl,
  cover,
  wrapLeft,
  wrapRight,
}) => {
  const [videoModalIsVisible, setVideoModalIsVisible] = useState<boolean>(false)
  const showModal = () => setVideoModalIsVisible(true)

  return (
    <Container wrapLeft={wrapLeft} wrapRight={wrapRight} cover={cover}>
      <div onClick={showModal} className="w-full">
        <ImgContainer>
          <Img
            fluid={imageUrl.childImageSharp.fluid}
            style={{
              width: "100%",
              height: "300px",
              marginBottom: 0,
              borderRadius: "10px",
            }}
          />
        </ImgContainer>
      </div>
      {/* <VideoEmbedModal
        embedUrl={embedUrl}
        show={videoModalIsVisible}
        close={() => setVideoModalIsVisible(false)}
      /> */}
      <ModalVideo
        url={embedUrl}
        channel="custom"
        autoplay={false}
        isOpen={videoModalIsVisible}
        onClose={() => setVideoModalIsVisible(false)}
      />
      <PlayIcon onClick={showModal} />
    </Container>
  )
}

export default VideoEmbed
