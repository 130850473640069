import Layout from "components/layout"
import SEO from "components/seo"
import React from "react"
import Post from "./Post"
import { IPageContext } from "api/types"
import parseISOWithOptions from "date-fns/esm/fp/parseISOWithOptions/index.js"

const Template: React.FC = (props: any) => {
  const pageContext: IPageContext = {
    data: props.pageContext.node,
    content: props.pageContext.node.content,
    articleType: props.pageContext.articleType,
    related_articles: props.pageContext.related_articles,
    previous_articles: props.pageContext.previous_articles,
    next_articles: props.pageContext.next_articles,
    topic_title: props.pageContext.topic_title,
    topic_id: props.pageContext.topic_id,
  }

  return (
    <Layout>
      <SEO title={pageContext.data.title} />
      <Post pageContext={pageContext} />
    </Layout>
  )
}

export default Template
