import EmbedVideoThumb from "components/EmbedVideoThumb"
import React, { useState } from "react"
import styled from "styled-components"
import { IRelatedVideo } from "api/types"
import ModalVideo from "react-modal-video"

const Container = styled.div`
  min-width: 280px;
  flex: 0.5;
  border: 1px solid lightgray;
  margin: 5px;
  border-radius: 12px;
  min-height: 330px;
`
const Text = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: ${props => props.theme.colors.secondary};
  margin-top: 24px;
  padding: 10px;
`

const VideoItem: React.FC<IRelatedVideo> = props => {
  const [videoModalIsVisible, setVideoModalIsVisible] = useState(false)
  const showVideo = () => setVideoModalIsVisible(true)

  return (
    <Container>
      <EmbedVideoThumb imageUrl={props.imageUrl} onClick={showVideo} />
      <Text>{props.title}</Text>
      <ModalVideo
        url={props.embedUrl}
        channel="custom"
        autoplay={false}
        isOpen={videoModalIsVisible}
        onClose={() => setVideoModalIsVisible(false)}
      />
    </Container>
  )
}

export default VideoItem
