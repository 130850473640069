import { toast } from "react-toastify"

const toastInfo = (
  _toast: typeof toast,
  text: string,
  position = "bottom-right"
) => {
  _toast.info(text, {
    //@ts-ignore
    position: position,
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
}

export default toastInfo
