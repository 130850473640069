import { IArticleMeta } from "models/ArticleMeta"
import React from "react"
import { Row } from "react-bootstrap"
import styled from "styled-components"
import { breakpoints } from "utils"
import { Heading2 } from "../ui"
import PreviousItem from "./PreviousItem"
import { IPrevNextArticle } from "api/types"

const Container = styled.div`
  margin-top: 20px;
`
const Wrapper = styled(Row)`
  @media (max-width: ${breakpoints.tablet}px) {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`

const renderPreviousItems = (posts: IPrevNextArticle[]) =>
  posts.map((post, i) => {
    return <PreviousItem key={i.toString()} {...post.node} />
  })

interface Props {
  items: IPrevNextArticle[]
}

const Previous: React.FC<Props> = props => {
  return (
    <Container>
      <Wrapper>{renderPreviousItems(props.items)}</Wrapper>
    </Container>
  )
}

export default Previous
