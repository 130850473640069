import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql"
import { Amplify } from "aws-amplify"
import React, { useEffect, useState } from "react"
import { toast, ToastContainer } from "react-toastify"
import styled from "styled-components"

import { AWS_CONFIG } from "../../../../aws-config"
import {
  FaRegThumbsDown,
  FaRegThumbsUp,
  FaThumbsDown,
  FaThumbsUp,
} from "react-icons/fa"
import toastError from "utils/toastError"
import toastInfo from "utils/toastInfo"
import { apiPostArticleFeedback } from "api/queries"

interface iFeedbackSection {
  reference: string
  userProfileExists: boolean
}

const Wrapper = styled.div`
  padding: 1rem;
  p {
    margin-bottom: 0;
    color: #163a74;
  }
  svg {
    display: block;
  }
`

const Content = styled.div`
  margin-top: 20px;
`

const Button: React.FC<{
  DefaultIcon: any
  SelectedIcon: any
  selected: boolean
  onClick: () => void
}> = ({ DefaultIcon, SelectedIcon, selected, onClick }) => {
  const Icon = selected ? SelectedIcon : DefaultIcon
  return (
    <button className="p-2 bg-gray-200 rounded-full" onClick={onClick}>
      {Icon}
    </button>
  )
}

const FeedbackSection: React.FC<iFeedbackSection> = ({
  reference,
  userProfileExists,
}) => {
  useEffect(() => {
    Amplify.configure(AWS_CONFIG)
  }, [])
  const [feedbackState, setFeedbackState] = useState<null | boolean>(null)
  const [reasonsPanelControls, setReasonsPanelControls] = useState<{
    show: boolean
    reasonsList: string[]
    question: string
  }>({
    show: false,
    reasonsList: [],
    question: "",
  })

  useEffect(() => {
    if (feedbackState === null) return
    if (feedbackState === true) {
      setReasonsPanelControls({
        show: true,
        reasonsList: ["Easy to understand", "Solved my problem", "Other"],
        question: "What is the reason of your feedback?",
      })
      return
    }
    if (feedbackState === false) {
      setReasonsPanelControls({
        show: true,
        reasonsList: [
          "Hard to understand",
          "Incorrect information",
          "Missing information",
          "Other",
        ],
        question: "What is the reason of your feedback?",
      })
      return
    }
  }, [feedbackState])

  const onClickThumb = async (feedbackState: boolean) => {
    setFeedbackState(feedbackState)
    apiPostArticleFeedback(
      { articleId: reference, isHelpful: feedbackState },
      userProfileExists
        ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        : GRAPHQL_AUTH_MODE.API_KEY
    )
      .then(data => {
        toastInfo(
          toast,
          `Thank you for your feedback. ${
            feedbackState
              ? "We are glad you liked it."
              : "We will make sure to improve our service."
          }`
        )
      })
      .catch(err => {
        console.error("Something went wrong", err)
        toastError(toast, "Could not record your feedback")
      })
  }

  return (
    <>
      <Content className="flex items-center justify-center">
        <Wrapper className="bg-white site-border-radius border flex items-center p-2">
          <p className="mr-6 text-sm">Did you find this article useful?</p>

          <div className="flex item-center justify-end">
            <div className="mr-2">
              <Button
                DefaultIcon={<FaRegThumbsUp color="#163a74" />}
                SelectedIcon={<FaThumbsUp color="#163a74" />}
                selected={feedbackState === true}
                onClick={() => onClickThumb(true)}
              />
            </div>
            <Button
              DefaultIcon={<FaRegThumbsDown color="#163a74" />}
              SelectedIcon={<FaThumbsDown color="#163a74" />}
              selected={feedbackState === false}
              onClick={() => onClickThumb(false)}
            />
          </div>
        </Wrapper>
      </Content>
      <ToastContainer />
    </>
  )
}

export default FeedbackSection
