import { Link } from "gatsby"
import React from "react"
import { Breadcrumb as RBreadcrumb } from "react-bootstrap"
import styled from "styled-components"

const Container = styled(RBreadcrumb)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: ${props => props.theme.colors.secondary};
  & .breadcrumb {
    background: none;
    margin: 0;
    padding: 0;
    flex-wrap: nowrap;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
  }
  & .breadcrumb-item.active {
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${props => props.theme.colors.secondary};
  }
  display: flex;
`

interface Item {
  text?: string
  link?: string
}

interface Props {
  items: Item[]
  activeItem: Item
  className?: string
}

const Breadcrumbs: React.FC<Props> = ({ className, items, activeItem }) => {
  let activeText = activeItem.text
  return (
    <Container className={className}>
      {items.map(item => {
        return (
          <RBreadcrumb.Item
            linkAs={Link}
            linkProps={{ to: item.link }}
            key={item.text}
          >
            {item.text}
          </RBreadcrumb.Item>
        )
      })}
      <RBreadcrumb.Item active>{activeText}</RBreadcrumb.Item>
    </Container>
  )
}

export default Breadcrumbs
