import React from "react"
import styled from "styled-components"
import Avatar from "react-avatar"
import theme from "components/layout/theme"
interface Props {
  title: string
  subtitle: string
  imageStyle?: any
  className?: string
  name?: string
}

const Profile = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
`
const Photo = styled(Avatar)`
  border-radius: 100%;
  width: 36px;
  margin: 0;
  margin-right: 10px;
`
const Text = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
`
const Title = styled(Text)`
  font-size: 14px;
  color: #333;
`
const Subtitle = styled(Text)`
  font-size: 12px;
  color: #666;
`

const ProfileCard: React.FC<Props> = props => {
  return (
    <Profile className={props.className}>
      {/* @ts-ignore */}
      <Photo name={props.name} size={36} color={theme.colors.primary} />
      <div>
        <Title>{props.title}</Title>
        <Subtitle>{props.subtitle}</Subtitle>
      </div>
    </Profile>
  )
}

export default ProfileCard
