import React from "react"
import styled, { css } from "styled-components"
import { Link } from "@reach/router"
import { FaExternalLinkAlt } from "react-icons/fa"
import { FaLink } from "react-icons/fa"
import theme from "components/layout/theme"

const Container = styled(Link)`
  display: flex;
  margin-bottom: 7px;
  color: ${theme.colors.primary};
`
const LinkWrapper = styled.a`
  display: flex;
  margin-bottom: 7px;
  color: ${theme.colors.primary};
`
const IconContainer = styled.div`
  height: 16px;
  width: 16px;
  margin-right: 10px;
`
const linkStyles = `
  height: 16px;
  width: 16px;
  margin-top: -10px;
  stroke: ${(props: any) => props.theme.colors.primary};
`
const LinkIcon = styled(FaLink)`
  ${linkStyles};
`
const ExtLinkIcon = styled(FaExternalLinkAlt)`
  ${linkStyles};
`
const Text = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: ${props => props.theme.colors.secondary};
`

interface Props {
  link: string
  text: string
  textStyles?: any
}

const ResourceLink: React.FC<Props> = props => {
  let Icon = LinkIcon

  if (props.link.startsWith("http")) {
    Icon = ExtLinkIcon
  }

  return !props.link.startsWith("http") ? (
    <Container to={props.link}>
      <IconContainer>
        <Icon />
      </IconContainer>
      <Text style={props.textStyles}>{props.text}</Text>
    </Container>
  ) : (
    <LinkWrapper href={props.link} target="_blank" rel="noopener">
      <IconContainer>
        <Icon />
      </IconContainer>
      <Text style={props.textStyles}>{props.text}</Text>
    </LinkWrapper>
  )
}

export default ResourceLink
