import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  padding: 0;
  margin: 0;
  font-family: Open Sans;
`

const textStyles = css`
  font-weight: normal;
  line-height: 24px;
  color: ${props => props.theme.colors.secondary};
  font-size: 16px;
`

export const Paragraph = styled.p`
  ${textStyles};
  margin-top: 16px;
`

export const Heading2 = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.03em;
  color: #163a74;
  margin: 36px 0 18px;
`

export const UnorderedList = styled.ul`
  ${textStyles};
`
