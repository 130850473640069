import React from "react"
import styled from "styled-components"

const Container: any = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  cursor: pointer;
  width: fit-content;
  &:hover {
    div {
      color: ${props => props.theme.colors.primary};
    }
    svg {
      stroke: ${props => props.theme.colors.primary};
    }
  }
  &.active {
    div {
      color: ${props => props.theme.colors.primary};
    }
    svg {
      fill: ${props => props.theme.colors.primary};
      stroke: ${props => props.theme.colors.primary};
    }
  }
`
const LinkText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.secondary};
`
const IconContainer = styled.div`
  margin-right: 12px;
`

interface Props {
  text: string
  icon: React.FC
  className?: string
  onClick: Function
}

const ActionLink: React.FC<Props> = ({
  className,
  text,
  icon: Icon,
  onClick,
}) => {
  return (
    <Container className={className} onClick={onClick}>
      <IconContainer>
        <Icon />
      </IconContainer>
      <LinkText>{text}</LinkText>
    </Container>
  )
}

export default ActionLink
